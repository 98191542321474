import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import useToken from "./useToken";
import { loginService } from "@/services/auth/login";
import {
  requestOtpService,
  passwordUpdateService,
} from "@/services/auth/resetPassword";
import { logoutService } from "@/services/auth/logout";
import { useToast } from "@/components/ui/use-toast";
import { useRouter } from "next/router";
import { User } from "@/types";
// import { ROLES } from "@/constants";
import { RequestOtpResponse } from "@/components/resetPassword/PasswordUpdate";

export default function useAuth() {
  const { clearToken, setToken } = useToken();
  const { toast } = useToast();
  const router = useRouter();

  const { mutate: login, isLoading: isLoginLoading } = useMutation(
    loginService,
    {
      onSuccess(data: AxiosResponse) {
        const userData = data?.data as User;
        setToken(userData.token!);
        toast({
          itemID: "login-success",
          title: "Success",
          description: "Logged in successfully",
        });
        router.reload();
      },
      onError(error: AxiosError) {
        toast({
          itemID: "login-error",
          // @ts-ignore
          title: error.response?.data?.message ?? "Something went wrong",
          variant: "destructive",
        });
      },
    }
  );

  const { mutate: logout, isLoading: isLogoutLoading } = useMutation(
    logoutService,
    {
      onSuccess() {
        clearToken();
        toast({
          itemID: "logout-success",
          title: "You have been logged out",
        });
        router.reload();
      },
      onError() {
        clearToken();
        toast({
          itemID: "logout-success",
          title: "You have been logged out",
        });

        router.reload();
        // toast({
        //   itemID: "logout-error",
        //   // @ts-ignore
        //   title: error.response?.data?.message ?? "Something went wrong",
        //   variant: "destructive",
        // });
      },
    }
  );

  const requestMutation = useMutation(requestOtpService, {
    onSuccess(data: AxiosResponse) {
      const requestOtpResponse = data?.data as RequestOtpResponse;
      toast({
        itemID: "top-request-success",
        title: "Success",
        description: requestOtpResponse.message,
      });
      return requestOtpResponse;
    },
    onError(error: AxiosError) {
      toast({
        itemID: "opt-request-error",
        // @ts-ignore
        title: error?.response?.data?.message ?? "Something went wrong",
        variant: "destructive",
      });
    },
  });

  const updatePasswordMutation = useMutation(passwordUpdateService, {
    onSuccess(data: AxiosResponse) {
      const requestOtpResponse = data?.data;
      console.log(
        "🚀 ~ file: useAuth.ts:125 ~ onSuccess ~ requestOtpResponse:",
        requestOtpResponse
      );
      toast({
        itemID: "password-update-success",
        title: "Success",
        description: "Your password successfully updated.",
      });
      router.push("/");
    },
    onError(error: AxiosError) {
      toast({
        itemID: "password-update-error",
        // @ts-ignore
        title: error?.response?.data?.message ?? "Something went wrong",
        variant: "destructive",
      });
    },
  });

  return {
    login,
    isLoginLoading,
    logout,
    isLogoutLoading,
    requestMutation,
    updatePasswordMutation,
  };
}
