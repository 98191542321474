import { useState, useEffect } from "react";
import { storeToken, removeToken, retrieveToken } from "@/lib/tokenStore";
import refreshTokenService from "@/services/auth/refreshToken";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "@/constants";

export default function useToken() {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState(false);

  useEffect(() => {
    const token = retrieveToken();
    setAccessToken(token);
  }, []);

  const setToken = (token: string): void => {
    if (token) {
      storeToken(token);
      setAccessToken(token);
    }
  };

  const hasToken = (): boolean => {
    if (retrieveToken()) {
      return true;
    }
    return false;
  };

  const clearToken = (): void => {
    removeToken();
    setAccessToken(null);
  };

  const { data } = useQuery({
    queryKey: [queryKeys.refreshToken],
    queryFn: refreshTokenService,
    enabled: refreshToken,
  });

  const tokenRefresh = () => setRefreshToken(true);

  useEffect(() => {
    if (data) {
      setToken(data?.data?.token);
      setRefreshToken(false);
    }
  }, [data]);

  return {
    token: accessToken,
    setToken,
    hasToken,
    clearToken,
    tokenRefresh,
    isLoggedIn: !!retrieveToken(),
  };
}
