import { endpoints } from "@/constants";
import api from "@/services/api";

const loginService = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const res = api.post(endpoints.auth.login, { email, password });
  return res;
};

export { loginService };
