import { endpoints } from "@/constants";
import { retrieveToken } from "@/lib/tokenStore";
import api from "../api";

const refreshToken = () => {
  if (retrieveToken()) {
    const res = api.get(endpoints.auth.refreshToken);
    return res;
  }
};

export default refreshToken;
