import { endpoints } from "@/constants";
import api from "@/services/api";
// import { User } from "@/types";

const logoutService = () => {
  const res = api.post(endpoints.auth.logout, {});
  return res;
};

export { logoutService };
