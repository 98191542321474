import Head from "next/head";
import LoginForm from "@/components/sections/login";
import ThemeSwitchBtn from "@/components/ui/ThemeSwitchBtn";
import Logo from "@/components/ui/logo";

const Login = () => {
  return (
    <>
      <Head>
        <title>Evolv Login</title>
      </Head>
      <header className="container pb-8 max-w-screen-xl items-center flex justify-between py-3">
        <Logo height={50} width={150} />
        <ThemeSwitchBtn />
      </header>
      <h1 className="text-center font-bold text-3xl mt-6 text-primary">Welcome Back!</h1>
      <div className="grid place-items-center px-4">
        <LoginForm />
      </div>
    </>
  );
};

export default Login;
