import { NextApiRequest } from "next";
import Cookies, { CookieSetOptions } from "universal-cookie";

const cookies = new Cookies();

const expirationDate = new Date();
expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000);

export const OPTIONS: CookieSetOptions = {
  path: "/",
  sameSite: true,
  expires: expirationDate,
};
export const KEY = "token";

export const storeToken = (token: string) => {
  cookies.set(KEY, token, OPTIONS);
};

export const retrieveToken = (): string | null => {
  try {
    const token = cookies.get(KEY);
    if (token) {
      return token;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const parseToken = (req: NextApiRequest): string | null => {
  const _cookies = new Cookies(req.headers.cookie);
  const token = _cookies.get(KEY);
  if (token) {
    return token;
  }
  return null;
};

export const removeTokenFromReq = (req: any): void => {
  const _cookies = new Cookies(req.headers.cookie);
  _cookies.remove(KEY, OPTIONS);
};

export const removeToken = (): void => {
  cookies.remove(KEY, OPTIONS);
};
