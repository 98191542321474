import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { BASE_URL } from "@/env";
import { retrieveToken } from "@/lib/tokenStore";

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use((config) => {
  const contentType =
    config.data instanceof FormData
      ? "multipart/form-data"
      : "application/json";

  config.headers["Content-Type"] = contentType;

  if (retrieveToken()) {
    config.headers.Authorization = `Bearer ${retrieveToken()}`;
  }
  return config;
});

export const request = async <T>(
  url: string,
  config: AxiosRequestConfig
): Promise<T> =>
  api
    .request<T>({
      url,
      ...config,
    })
    .then((res: AxiosResponse) => res.data);

export default api;
