import { Button } from "@/components/ui/button";
import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";
import { useMantineColorScheme } from "@mantine/core";

const ThemeSwitchBtn = () => {
  const { setColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const { theme, setTheme } = useTheme();

  return (
    <Button
      variant={"outline"}
      className="rounded-full aspect-square p-2"
      onMouseDown={() => {
        setColorScheme(theme === "light" ? "dark" : "light");
        setTheme(theme === "light" ? "dark" : "light");
      }}
    >
      {theme === "light" ? <Moon strokeWidth={1.5} /> : <Sun />}
    </Button>
  );
};

export default ThemeSwitchBtn;
