import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import Link from "next/link";
import { Checkbox } from "@/components/ui/checkbox";
import { PasswordInput, TextInput } from "@/components/ui/FormItems";
import useAuth from "@/hooks/useAuth";
import { Loader } from "@/components/ui/Loader";

const loginSchema = z.object({
  email: z.string({ required_error: "Email is required" }),
  password: z.string({ required_error: "Password is required" }),
});
type Data = z.infer<typeof loginSchema>;

function LoginForm() {
  const { login, isLoginLoading } = useAuth();

  const form = useForm<Data>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<Data> = (data) => {
    login(data);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="mt-12 h-max max-w-sm w-full bg-accent/20 shadow-xl px-6 py-8 rounded-xl"
      >
        <div className="space-y-7">
          <h1 className="text-3xl font-bold text-center">Login</h1>
          <TextInput
            name="email"
            label="Email"
            placeholder="Email"
            form={form}
            required
            type="email"
          />
          <PasswordInput required form={form} />
        </div>

        <div className="flex my-4 justify-between items-center">
          <div className="flex items-center space-x-2">
            <Checkbox id="terms" />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 select-none cursor-pointer"
            >
              Remember me
            </label>
          </div>
          <Button className="pr-0 brightness-75" asChild variant={"link"}>
            <Link href="/reset-password">Forgot Password?</Link>
          </Button>
        </div>

        <div className="flex gap-4">
          <Button
            type="reset"
            onClick={() => form.reset()}
            className="w-1/2"
            variant={"secondary"}
            disabled={isLoginLoading}
          >
            Reset
          </Button>
          <Button
            disabled={isLoginLoading}
            type="submit"
            className="w-1/2 brightness-95 inline-grid place-items-center"
          >
            {isLoginLoading ? <Loader /> : "Login"}
          </Button>
        </div>
      </form>
    </Form>
  );
}

export default LoginForm;
