import { endpoints } from "@/constants";
import api from "@/services/api";

const requestOtpService = ({
  email,
}: {
  email: string;
}) => {
  const res = api.post(endpoints.auth.resetPassword, { email });
  return res;
};

const passwordUpdateService = ({
  otp,
  token,
  password
}: {
  otp: string;
  token: string;
  password: string;
}) => {
  const res = api.post(endpoints.auth.updatePassword, { otp, token, password });
  return res;
};

export { requestOtpService, passwordUpdateService };
