import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";

export const Loader = ({ className }: { className?: string }) => {
  return <Loader2 className={cn("animate-spin", className)} />;
};

export const LoadingOverlay = () => {
  return (
    <div className="absolute grid place-items-center bg-accent/50 backdrop-blur-sm inset-0">
      <Loader />
    </div>
  );
};
