import Link from "next/link";
import Image from "next/image";

const Logo = ({
  height = 50,
  width = 140,
  className = "",
}: {
  height?: number;
  width?: number;
  className?: string;
}) => {
  return (
    <Link href={"/"} className={` inline-block ${className}`}>
      <Image
        src="/evolv.png"
        alt="Evolv"
        height={height}
        width={width}
        className="object-contain max-h-16"
      />
    </Link>
  );
};

export default Logo;
